#container-json {
  height: 700px;
  margin: 100px auto;
  overflow: auto;
  padding: 0;
  position: relative;
  width: auto;
}

#container-json .content-json {
  height: 700px;
  width: auto;
}
