* {
  a,
  a:hover,
  a:visited {
    text-decoration: none !important;
  }
}

.c-app {
  background-color: #ebedef !important;
}

.c-sidebar {
  background-color: #20212c !important;
}

.c-sidebar-brand {
  background-color: #20212c !important;
}

.c-sidebar-nav-item {
  cursor: pointer;
}

.c-header.c-header-dark {
  background-color: #20212c !important;
}

.c-footer {
  background-color: #fff !important;
}
