.btn-whatsapp-logo {
  max-width: 6%;
}

.btn-google-plus {
  background-color: #d34836;
  border-color: #d34836;
  color: #fff;
}

.btn-google-plus:hover {
  background-color: #ba3929;
  border-color: #b03626;
  color: #fff;
}

.btn-google-plus:focus,
.btn-google-plus.focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 99, 84, 50%);
}

.btn-google-plus.disabled,
.btn-google-plus:disabled {
  background-color: #d34836;
  border-color: #d34836;
  color: #fff;
}

.btn-google-plus:not(:disabled, .disabled):active,
.btn-google-plus:not(:disabled, .disabled).active,
.show > .btn-google-plus.dropdown-toggle {
  background-color: #b03626;
  border-color: #a53324;
  color: #fff;
}

.btn-google-plus:not(:disabled, .disabled):active:focus,
.btn-google-plus:not(:disabled, .disabled).active:focus,
.show > .btn-google-plus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 99, 84, 50%);
}

.btn-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:hover {
  background-color: #30497c;
  border-color: #2d4373;
  color: #fff;
}

.btn-facebook:focus,
.btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 50%);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:not(:disabled, .disabled):active,
.btn-facebook:not(:disabled, .disabled).active,
.show > .btn-facebook.dropdown-toggle {
  background-color: #2d4373;
  border-color: #293e6a;
  color: #fff;
}

.btn-facebook:not(:disabled, .disabled):active:focus,
.btn-facebook:not(:disabled, .disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 50%);
}
