.custom-form-error {
  background: $danger;
  border-radius: 0 0 3px 3px;
  color: $white;
  font-size: 10px;
  line-height: 1;
  margin-top: -1px;
  padding: 6px 10px;
  text-transform: uppercase;
}

.custom-form-error-border {
  border: 1px solid $danger;
}

.custom-form-warning {
  background: $warning;
  border-radius: 0 0 3px 3px;
  color: $black;
  font-size: 10px;
  line-height: 1;
  margin-top: -1px;
  padding: 6px 10px;
  text-transform: uppercase;
}

.custom-form-warning-border {
  background: $warning;
}
